import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataModel, extend } from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { SkeletonBlock } from '@one-thd/sui-atomic-components';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { dataModel } from '../dataModel';
import { SpecificationGroupContent } from '../component/SpecificationGroupContent';
import { track } from '../specifications-analytics';

export const SpecificationsWithKPFAccordionBodyComponent = (props) => {
  const {
    itemId,
    oneColumn,
    returnableStatus,
    isWhiteSpecTitle
  } = props;
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const { data, loading } = useDataModel('product', { variables: { itemId } });

  useEffect(() => { track('accordion-open'); }, []);

  if (loading) {
    return (
      <div
        className="sui-w-full"
        data-component="SpecificationsAccordionBodyPlaceholder"
      >
        <SkeletonBlock aspect="wide" height={52} width="100%" />
      </div>
    );
  }

  if (!data || !data?.product?.specificationGroup) {
    return null;
  }

  const { specificationGroup } = data.product;

  return (
    <div
      className="sui-flex sui-flex-wrap sui-w-full sui-box-border
      sui-pointer-events-auto sui-gap-6"
      id="specifications"
      data-component="SpecificationsHDHPIPAccordionBody"
    >
      <KeyProductFeatures
        itemId={itemId}
        maxfeatures={8}
        minfeatures={isMobile ? 4 : 3}
        channel={channel}
        displaySimilarLinks
        twoColumnGrid={isMobile}
      />
      <SpecificationGroupContent
        specificationGroup={specificationGroup}
        oneColumn={oneColumn}
        returnableStatus={returnableStatus}
        isWhiteSpecTitle={isWhiteSpecTitle}
      />
    </div>
  );
};

SpecificationsWithKPFAccordionBodyComponent.displayName = 'SpecificationsWithKPFAccordionBody';

SpecificationsWithKPFAccordionBodyComponent.dataModel = extend({}, dataModel, KeyProductFeatures);

const propTypes = {
  /** SKU Number */
  itemId: PropTypes.string.isRequired,
  returnableStatus: PropTypes.bool,
  oneColumn: PropTypes.bool,
  isWhiteSpecTitle: PropTypes.bool
};

const defaultProps = {
  oneColumn: false,
  returnableStatus: false,
  isWhiteSpecTitle: false
};

SpecificationsWithKPFAccordionBodyComponent.propTypes = propTypes;
SpecificationsWithKPFAccordionBodyComponent.defaultProps = defaultProps;

export const SpecificationsWithKPFAccordionBody = withErrorBoundary(SpecificationsWithKPFAccordionBodyComponent);

SpecificationsWithKPFAccordionBody.dataModel = extend({}, dataModel, KeyProductFeatures);
SpecificationsWithKPFAccordionBody.propTypes = propTypes;
SpecificationsWithKPFAccordionBody.defaultProps = defaultProps;
