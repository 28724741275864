/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { cloneElement, isValidElement, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataModel, extend } from '@thd-nucleus/data-sources';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent, AppContext } from '@thd-nucleus/app-render';

import {
  Card, CardContent, CardTitle,
  CardBody, Modal, ModalBody, ModalHeader, IconButton
} from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { dataModel } from '../dataModel';
import { SpecificationGroupContent } from './SpecificationGroupContent';
import { SpecificationsPlaceholder } from './placeholders/SpecificationsPlaceholder';

export const SpecificationsMobileComponent = (props) => {
  const {
    itemId,
    hideMobileFade,
    children,
    oneColumn
  } = props;
  const { channel, isServer } = useContext(ExperienceContext);
  const [open, setOpen] = useState(false);
  const handleModal = () => setOpen((toggle) => !toggle);

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;

  if (loading) return <SpecificationsPlaceholder />;

  if (!data || (error && !data)) {
    return null;
  }

  if (!data?.product?.specificationGroup) {
    return null;
  }

  const { specificationGroup } = data.product;

  if (!specificationGroup.length) {
    return null;
  }
  const afterClasses = `after:sui-content-[" "] after:sui-absolute
    after:sui-bottom-0 after:sui-left-0 after:sui-right-0 after:sui-clear-both after:sui-h-3/6 after:sui-block`;

  return (
    <div
      className="box-border sui-flex sui-flex-wrap sui-w-full sui-p-5 sui-pointer-events-auto"
      id="specifications"
      data-component="SpecificationsMobile"
    >
      <Card className="sui-border-0 sui-w-full">
        <CardContent
          onClick={handleModal}
          disablePadding
        >
          {!hideMobileFade && (
            <CardTitle
              header="Specifications"
              action={<IconButton icon={ArrowForward} color="brand" />}
            />
          )}
          <CardBody>
            <div
              data-component="SpecificationsCardBody"
              className={`sui-border-0
                ${!hideMobileFade
                  && `sui-relative sui-h-52 sui-overflow-hidden ${afterClasses}
                  after:[background:linear-gradient(180deg,rgba(255,255,255,0)_0,rgba(255,255,255,0.75)_54%,#FFF)]`
                }`}
            >
              {children
                ? cloneElement(children, {
                  itemId,
                  maxfeatures: 4,
                  minfeatures: 4,
                  channel
                })
                : <SpecificationGroupContent specificationGroup={specificationGroup} oneColumn={oneColumn} />}
              {(children && isServer && thdSeoBotDetected) && (
                <div
                  className="sui-hidden"
                >
                  <SpecificationGroupContent specificationGroup={specificationGroup} />
                </div>
              )}
            </div>
          </CardBody>
        </CardContent>
        <Modal open={open} size="lg" data-testid="specs-table-modal">
          <ModalHeader onClose={handleModal}>Specifications</ModalHeader>
          <ModalBody>
            {children && cloneElement(children, {
              itemId,
              maxfeatures: 8,
              minfeatures: 4,
              channel,
              displaySimilarLinks: true
            })}
            <SpecificationGroupContent specificationGroup={specificationGroup} oneColumn={oneColumn} />
          </ModalBody>
        </Modal>
      </Card>

    </div>
  );
};

SpecificationsMobileComponent.displayName = 'SpecificationsMobile';

SpecificationsMobileComponent.dataModel = extend({}, dataModel, KeyProductFeatures);

const propTypes = {
  /** SKU Number */
  itemId: PropTypes.string.isRequired,
  /** Optional fade and click effects for mobile */
  hideMobileFade: PropTypes.bool,
  /** Can take an (optional) single child component of type KeyProductFeatures */
  children: (props, propName, componentName) => {
    const prop = props[propName];
    let error;
    if (prop && (!isValidElement(prop) || prop.type.name !== 'KeyProductFeatures')) {
      error = new Error(`'${componentName}' only accepts a single child of type 'KeyProductFeatures'`);
    }
    return error;
  },
  oneColumn: PropTypes.bool,
};

const defaultProps = {
  children: null,
  hideMobileFade: false,
  oneColumn: false,
};

SpecificationsMobileComponent.propTypes = propTypes;
SpecificationsMobileComponent.defaultProps = defaultProps;

const HydratedComponent = withHydrator({
  id: 'specifications-mobile',
  scrollBuffer: 750,
  delay: 1500,
  placeholder: <SpecificationsPlaceholder />
}, SpecificationsMobileComponent);
const DynamicComponent = withDynamicComponent(HydratedComponent);

export const SpecificationsMobile = withErrorBoundary(DynamicComponent);

SpecificationsMobile.dataModel = extend({}, dataModel, KeyProductFeatures);
SpecificationsMobile.propTypes = propTypes;
SpecificationsMobile.defaultProps = defaultProps;