import React, {
  isValidElement, useContext, useState, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionBody, AccordionHeader } from '@one-thd/sui-atomic-components';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { AppContext } from '@thd-nucleus/app-render';
import { dataModel } from '../dataModel';
import { SpecificationsAccordionTitle } from './SpecificationsAccordionTitle';
import { SpecificationsAccordionBodyComponent } from './SpecificationsAccordionBody';

export const SpecificationsAccordionComponent = (props) => {
  const {
    itemId,
    children,
    oneColumn,
    returnableStatus,
    isWhiteSpecTitle,
    showSubtitle,
  } = props;
  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (canCloseAccordion = true) => {
    if (canCloseAccordion || (!canCloseAccordion && expanded)) {
      setExpanded(!expanded);
    }

    if (!canCloseAccordion && !expanded) {
      setExpanded(true);
    }
  };

  useEffect(() => {
    window.LIFE_CYCLE_EVENT_BUS.on('product-section-key-feat.click', ({ output = true }) => {
      handleAccordionChange(output);
    });
  }, []);

  return (
    <Accordion
      key="specifications-accordion"
      defaultExpanded={thdSeoBotDetected}
      expanded={expanded}
      onChange={() => handleAccordionChange()}
    >
      <AccordionHeader>
        <div className="sui-flex sui-flex-col">
          <SpecificationsAccordionTitle itemId={itemId} showSubtitle={showSubtitle} />
        </div>
      </AccordionHeader>
      <AccordionBody>
        <SpecificationsAccordionBodyComponent
          itemId={itemId}
          oneColumn={oneColumn}
          returnableStatus={returnableStatus}
          isWhiteSpecTitle={isWhiteSpecTitle}
        >
          {children}
        </SpecificationsAccordionBodyComponent>
      </AccordionBody>
    </Accordion>
  );
};

SpecificationsAccordionComponent.displayName = 'SpecificationsAccordionComponent';

SpecificationsAccordionComponent.dataModel = dataModel;

const propTypes = {
  /** SKU Number */
  itemId: PropTypes.string.isRequired,
  returnableStatus: PropTypes.bool,
  /** Can take an (optional) single child component of type KeyProductFeatures */
  children: (props, propName, componentName) => {
    const prop = props[propName];
    let error;
    if (prop && (!isValidElement(prop) || prop.type.name !== 'KeyProductFeatures')) {
      error = new Error(`'${componentName}' only accepts a single child of type 'KeyProductFeatures'`);
    }
    return error;
  },
  oneColumn: PropTypes.bool,
  isWhiteSpecTitle: PropTypes.bool,
  showSubtitle: PropTypes.bool,
};

const defaultProps = {
  children: null,
  oneColumn: false,
  returnableStatus: false,
  isWhiteSpecTitle: false,
  showSubtitle: true,
};

SpecificationsAccordionComponent.propTypes = propTypes;
SpecificationsAccordionComponent.defaultProps = defaultProps;

export const SpecificationsAccordion = withErrorBoundary(SpecificationsAccordionComponent);

SpecificationsAccordion.dataModel = dataModel;
SpecificationsAccordion.propTypes = propTypes;
SpecificationsAccordion.defaultProps = defaultProps;
