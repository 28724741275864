import React from 'react';
import { string } from 'prop-types';
import { SkeletonBlock, SkeletonLine, SkeletonContent } from '@one-thd/sui-atomic-components';

export const SpecificationsPlaceholder = ({ channel }) => {
  const isMobile = channel === 'mobile';
  const height = isMobile ? 64 : 100;
  const textRows = isMobile ? 4 : 10;

  return (
    <div
      className="sui-pb-2 sm:sui-pb-3 sui-box-border sui-w-full sui-flex sui-flex-wrap"
      data-component="SpecificationsPlaceholder"
    >
      <div className="sui-w-full sui-p-0">
        <SkeletonBlock aspect="wide" height={height} width="100%" />
      </div>

      <div className="sui-w-full sui-p-0">
        <SkeletonContent initialSize="full" disableShrink disablePadding="x">
          <SkeletonLine fullWidth numberOfLines={textRows} variant="multi" />
        </SkeletonContent>
      </div>
    </div>
  );
};

SpecificationsPlaceholder.propTypes = {
  channel: string
};

SpecificationsPlaceholder.defaultProps = {
  channel: 'mobile'
};

SpecificationsPlaceholder.displayName = 'SpecificationsPlaceholder';