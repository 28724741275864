/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { string, bool, arrayOf, oneOfType } from 'prop-types';
import { Typography, Link } from '@one-thd/sui-atomic-components';

const KeyProductFeatureValue = (props) => {
  const [hideMore, setHideMore] = useState(true);
  const maxLimit = 3;
  const { value, simple } = props;
  const isValueString = typeof value === 'string';

  if (isValueString) {
    return <span>{value}</span>;
  }

  const shownLabel = hideMore ? value.slice(0, maxLimit) : value;

  const labels = () => {
    return simple ? (
      <Typography truncate variant="body-sm" weight="bold">{shownLabel[0]}</Typography>
    ) : (shownLabel.map((label) => (
      <Typography component="p" variant="h2" color="subtle" key={label}>{label}</Typography>
    )));
  };

  return (
    <>
      {
        shownLabel && labels()
      }
      {
        value.length > maxLimit && !simple && (
          <>
            <div className="sui-text-left" data-testid="show-toggle-btn">
              <Link
                component="button"
                onClick={() => setHideMore(!hideMore)}
              >{!hideMore ? 'Show Less' : 'Show More'}
              </Link>
            </div>
          </>
        )
      }
    </>
  );

};

KeyProductFeatureValue.propTypes = {
  value: oneOfType([string, arrayOf(string)]),
  simple: bool
};

KeyProductFeatureValue.defaultProps = {
  value: null,
  simple: false
};

export { KeyProductFeatureValue };
