/* eslint-disable tailwindcss/no-arbitrary-value */
// DEPRECATED: Use the channel specific components. This is kept for backwards compatibility
import React, { cloneElement, isValidElement, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataModel, extend } from '@thd-nucleus/data-sources';
import {
  Typography, Card, CardContent, CardTitle,
  CardBody, Modal, ModalBody, ModalHeader, Table,
  TableBody, TableRow, TableCell, IconButton
} from '@one-thd/sui-atomic-components';
import { ArrowForward } from '@one-thd/sui-icons';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { AppContext } from '@thd-nucleus/app-render';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { dataModel } from '../dataModel';
import { getSortedValue } from '../helpers';

const GROUP_TYPES = {
  DIMENSIONS: 'Dimensions',
  DETAILS: 'Details',
  WARRANTY: 'Warranty / Certifications'
};
const Specifications = (props) => {
  const {
    itemId,
    hideMobileFade,
    children,
    oneColumn,
    hideSpecificationHeader
  } = props;
  const { channel, isServer } = useContext(ExperienceContext);
  const { instance } = useContext(AppContext);
  const thdSeoBotDetected = instance?.thdSEOBotDetection;
  const getAttrContent = (attributeGroupList) => {
    const attributeValues = getSortedValue({ attributeGroupList });
    return (
      <>
        <Table>
          <TableBody>
            <TableRow
              className={`sui-grid sui-grid-cols-2 ${!oneColumn && 'lg:sui-grid-cols-4'}`}
            >
              {(attributeValues || []).map((attr, i) => (
                <React.Fragment key={i}>
                  <TableCell
                    className="sui-bg-subtle break-words sui-p-3 sui-flex sui-border-primary sui-border-solid"
                    component="th"
                  >
                    {attr.specName}
                  </TableCell>
                  <TableCell
                    className="sui-p-3 sui-border-primary sui-border-solid"
                  >
                    {attr.specValue}
                  </TableCell>
                </React.Fragment>
              ))}
              {attributeValues && attributeValues.length % 2 === 1 && (
                <>
                  <TableCell
                    className="sui-hidden"
                    component="th"
                    data-component="empty-cell"
                  />
                  <TableCell
                    className="sui-hidden"
                    data-component="empty-cell"
                  />
                </>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };
  const getRenderingContent = (specificationGroup) => (
    <>
      {(specificationGroup)
        .filter((group) => group.specTitle === GROUP_TYPES.DIMENSIONS)
        .map((attrGroupList, i) => (
          <div key={i} className="sui-flex sui-flex-wrap sui-w-full">
            <div className="sui-w-full sui-p-0">
              <div className="sui-pt-5 sui-pb-3">
                <Typography variant="h5" component="h4">{attrGroupList.specTitle}</Typography>
              </div>
              {getAttrContent(attrGroupList)}
            </div>
          </div>
        ))}
      {(specificationGroup)
        .filter((group) => group.specTitle === GROUP_TYPES.DETAILS)
        .map((attrGroupList, i) => (
          <div key={i} className="sui-flex sui-flex-wrap sui-w-full">
            <div className="sui-w-full sui-p-0">
              <div className="sui-pt-5 sui-pb-3">
                <Typography variant="h5" component="h4">
                  {attrGroupList.specTitle}
                </Typography>
              </div>
              {getAttrContent(attrGroupList)}
            </div>
          </div>
        ))}
      {(specificationGroup)
        .filter((group) => group.specTitle === GROUP_TYPES.WARRANTY)
        .map((attrGroupList, i) => (
          <div key={i} className="sui-flex sui-flex-wrap sui-w-full">
            <div className="sui-w-full sui-p-0">
              <div className="sui-pt-5 sui-pb-3">
                <Typography variant="h5" component="h4">
                  {attrGroupList.specTitle}
                </Typography>
              </div>
              {getAttrContent(attrGroupList)}
            </div>
          </div>
        ))}
      <div className="sui-flex sui-flex-wrap sui-w-full">
        <div className="sui-w-full sui-p-0">
          <div id="thdFeedbackPip" className="sui-underline sui-py-2" suppressHydrationWarning />
        </div>
      </div>
    </>
  );
  const renderDesktopSpecification = (renderedContent) => (
    <div className="sui-flex sui-flex-wrap sui-w-full">
      {!hideSpecificationHeader && <Typography variant="h2">Specifications</Typography>}
      {children && cloneElement(children, {
        itemId,
        maxfeatures: 8,
        minfeatures: 3,
        channel,
        displaySimilarLinks: true
      })}
      {renderedContent}
    </div>
  );
  const renderMobileSpecification = (renderedContent, open, handleModal) => {
    const afterClasses = `after:sui-content-[" "] after:sui-absolute
    after:sui-bottom-0 after:sui-left-0 after:sui-right-0 after:sui-clear-both after:sui-h-3/6 after:sui-block`;
    return (
      <>
        <Card className="sui-border-0 sui-w-full">
          <CardContent
            onClick={handleModal}
            disablePadding
          >
            {!hideMobileFade && (
              <CardTitle
                header="Specifications"
                action={<IconButton icon={ArrowForward} color="brand" />}
              />
            )}
            <CardBody>
              <div
                data-component="SpecificationsCardBody"
                className={`sui-border-0
                  ${!hideMobileFade
                    && `sui-relative sui-h-52 sui-overflow-hidden ${afterClasses}
                    after:[background:linear-gradient(180deg,rgba(255,255,255,0)_0,rgba(255,255,255,0.75)_54%,#FFF)]`
                  }`}
              >
                {children
                  ? cloneElement(children, {
                    itemId,
                    maxfeatures: 4,
                    minfeatures: 4,
                    channel
                  })
                  : renderedContent}
                {(children && isServer && thdSeoBotDetected) && (
                  <div className="sui-hidden">
                    {renderedContent}
                  </div>
                )}
              </div>
            </CardBody>
          </CardContent>
          <Modal onClose={handleModal} open={open} size="lg" data-testid="specs-table-modal">
            <ModalHeader onClose={handleModal}>Specifications</ModalHeader>
            <ModalBody>
              {children && cloneElement(children, {
                itemId,
                maxfeatures: 8,
                minfeatures: 4,
                channel,
                displaySimilarLinks: true
              })}
              {renderedContent}
            </ModalBody>
          </Modal>
        </Card>
      </>
    );
  };
  const renderSpecs = (specificationGroup, open, handleModal) => {
    const renderedContent = getRenderingContent(specificationGroup);
    return (
      <div
        className="sui-flex sui-flex-wrap sui-w-full sui-box-border sui-mr-0 sui-p-0 md:sui-p-2 md:sui-mr-5"
        id="specifications"
        data-component="Specifications"
      >
        {channel !== 'mobile'
          ? renderDesktopSpecification(renderedContent)
          : renderMobileSpecification(renderedContent, open, handleModal)}
      </div>
    );
  };

  const { data, loading, error } = useDataModel('product', { variables: { itemId } });

  const [open, setOpen] = useState(false);
  const handleModal = () => setOpen((toggle) => !toggle);

  if (loading && !data) {
    return null;
  }
  if (error && !data) {
    return null;
  }
  if (!data?.product?.specificationGroup) {
    return null;
  }
  const { specificationGroup } = data.product;
  if (!specificationGroup.length) {
    return null;
  }
  return renderSpecs(specificationGroup, open, handleModal);
};
Specifications.displayName = 'Specifications';
Specifications.propTypes = {
  /** SKU Number */
  itemId: PropTypes.string.isRequired,
  /** makes only one column of specification KVPs regardless of screen size */
  oneColumn: PropTypes.bool,
  /** hides the fade for mobile specifications */
  hideMobileFade: PropTypes.bool,
  /** Can take an (optional) single child component of type KeyProductFeatures */
  children: (props, propName, componentName) => {
    const prop = props[propName];
    let error;
    if (prop && (!isValidElement(prop) || prop.type.name !== 'KeyProductFeatures')) {
      error = new Error(`'${componentName}' only accepts a single child of type 'KeyProductFeatures'`);
    }
    return error;
  },
  hideSpecificationHeader: PropTypes.bool
};
Specifications.defaultProps = {
  hideMobileFade: false,
  oneColumn: false,
  children: null,
  hideSpecificationHeader: false
};
Specifications.dataModel = extend({}, dataModel, KeyProductFeatures);
export { Specifications };
